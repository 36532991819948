import { FormlyPhoneInput } from './auth/survey/formly-phone-input';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppComponent } from './app.component';
import { ReferralFormComponent } from './referral-form/referral-form.component';
import { HomeComponent } from './home/home.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { AuthComponent } from './auth/auth.component';

import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { ErrorComponent } from './error/error.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    AppComponent,
    ReferralFormComponent,
    HomeComponent,
    AuthComponent,
    ErrorComponent,
    ThankyouComponent,
    FormlyPhoneInput
  ],
  imports: [
    BrowserModule,
    AuthModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      validationMessages:[
        {name: 'required', message: 'This field is required'}
      ], 
      extras: { 
        lazyRender: true 
      },
      types: [
        { name: 'phoneInput', component: FormlyPhoneInput },
      ],      
    }), 
    NgxMaskModule.forRoot(options),   
    FormlyBootstrapModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
