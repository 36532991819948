
<div class="slide bg-mask background-image full-vh">
    <div class="container-slide vertical-align left head-desc">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <form [formGroup]="form" class="form-bg" (ngSubmit)="onSubmit()">
                        
                        <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
                        <button type="submit" class="btn btn-default">Let's Begin</button>
                    
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>