import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'formly-phone-input',
    template:`
        <input type="text" 
        [formControl]="formControl"
        class="form-control"
        [formlyAttributes]="field"
        [class.is-invalid]="showError"
        mask="(000) 000-0000" />
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class FormlyPhoneInput extends FieldType {
    formControl!: FormControl;
}