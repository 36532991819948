import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'referral',
  templateUrl: './referral-form.component.html',
  styleUrls: ['./referral-form.component.sass']
})
export class ReferralFormComponent {

  constructor() { }

  form = new FormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [{
    key: 'referral-number',
    type: 'input',
    templateOptions: {
      placeholder: 'Enter Referral Number',
      require: true,
    }
  }
  ];


  onSubmit(){
    console.log(this.model);
  }

}
