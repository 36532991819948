<div class="slide bg-mask background-image full-vh">
    <div class="container-slide vertical-align left head-desc">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="survey-intro">
                        <p>DevShield is a premium offshore outsourcing agent helping business of all sizes that need technical staff for 
                        operations, testing, deployment, development or support. We represent a firm trusted for over a decade 
                        by companies like Google. Our goal is to be the most affordable, trusted, efficient and quality solution available. 
                        </p>
                    
                        <p>Please answer the following questions. Based on your answers we will provide a recommendation specific to your needs.</p>
                    </div>
                    <form [formGroup]="form" class="form-bg" (ngSubmit)="onSubmit()">
                        
                        <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
                        <button type="submit" class="btn btn-default">Submit</button>
                    
                    </form>
                    <div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
