import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ReferralFormComponent } from './referral-form/referral-form.component'; // Probably can be removed
import { RefCodeComponent } from './auth/ref-code/ref-code.component'
import { ErrorComponent } from './error/error.component'
import { ThankyouComponent } from './thankyou/thankyou.component'
import { AuthComponent } from './auth/auth.component';

export const appRoutes: Routes =  [
    {path: '', component: HomeComponent},
    {path: 'referral', component: ReferralFormComponent},
    {path: 'ref-code', component: RefCodeComponent},
    {path: 'thankyou', component: ThankyouComponent},
    {path: 'auth', component: AuthComponent},
    {path: '**', component: ErrorComponent }  // Wildcard route to error page
];