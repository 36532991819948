import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ref-code',
  templateUrl: './ref-code.component.html',
  styleUrls: ['./ref-code.component.sass']
})
export class RefCodeComponent {

  constructor(private auth: AuthService, private router: Router) { }

  referralCode: string = "";
  errors: any = [];

  form = new FormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [{
    key: 'ReferralNumber',
    type: 'input',
    templateOptions: {
      placeholder: 'Enter Referral Number',
      require: true,
    }
  }
  ];


  onSubmit(){
    console.log(this.model);
    this.referralCode = this.model.ReferralNumber;
    this.auth.valRefCode(this.model)
      .subscribe((token) => {
        this.router.navigate(['/auth/survey'], { queryParams: { 'ReferralCode': this.referralCode}});
      },
      (errorResponse) => {
        console.log(this.errors);        
      });
  }

}
