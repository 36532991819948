<div class="slider" id="top">
    <div class="wrap-header">
      <!-- Start slide-->
      <div class="slide bg-mask background-image full-vh" style="background-image: url('assets/img/404.jpg')" >
        <div class="container-slide vertical-align center">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h1 class="heading-title-big">
                   Page <span>404</span></h1>
                <div class="description-slide">This page does not exist, but you can go to <span>back.</span></div>
                <div class="buttons-section"><a class=" btn dark-btn large-btn" href="index.html">Return to Home</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Old browsers support--><!--[if lt IE 9]>
<script src="libs/html5shiv/es5-shim.min.js"></script>
<script src="libs/html5shiv/html5shiv.min.js"></script>
<script src="libs/html5shiv/html5shiv-printshiv.min.js"></script>
<script src="libs/respond/respond.min.js"></script>
<![endif]-->
