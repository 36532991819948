import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.sass']
})
export class SurveyComponent implements OnInit {

  refCode = '';
  form = new FormGroup({});

  model = {
    BudgetConcerns: '',
    CompanyName: '',
    ContactEmail: '',
    ContactFirstName: '',
    ContactLastName: '',
    ContactPhoneNumber: '',
    CountryOfFormation: '',
    DevelopmentStack: '',
    IdealExperience: '',
    NeedSeniorTeamMembers: false,
    OffshoreExperience: '',
    PreferredLanguage: '',
    PreviousOffshoreExperience: false,
    ProjectDescription: '',
    ProjectDuration: '',
    ProjectManagementSoftware: '',
    ProjectTimeFrame: '',
    ReferralCode: this.refCode,
    SeniorTeamMembers: '',
    StateOfFormation: '',
    TargetRoles: [''],
    TechTeam: '',
    TimeAvailability: '',
    Timezone: ''
  };

  options: FormlyFormOptions = {};

  fields: FormlyFieldConfig[] = [
    {
      key: 'referralCode',
    },
    {
      key: 'CompanyName',
      type: 'input',
      templateOptions: {
        placeholder: '--Company Name*',
        required: true,
      },
    },
    {
      key: 'ContactFirstName',
      type: 'input',
      templateOptions:{
        placeholder: '--Contact\'s First Name*',
        required: true,
      },
    },
    {
      key: 'ContactLastName',
      type: 'input',
      templateOptions:{
        placeholder: '--Contact\'s Last Name*',
        required: true,
      },
    },
    {
      key: 'ContactEmail',
      type: 'input',
      templateOptions:{
        placeholder: '--Contact\'s Email*',
        required: true,
      },
    },
    {
      key: 'ContactPhoneNumber',
      type: 'phoneInput',
      templateOptions:{
        placeholder: '--Contact\'s Phone Number*',
        required: true,
      },
    },
    {
      key: 'CountryOfFormation',
      type: 'input',
      templateOptions:{
        placeholder: '--Company\'s Country of Formation*',
        required: true,
      },
    },
    {
      key: 'StateOfFormation',
      type: 'input',
      templateOptions: {
        placeholder: '--Company\'s State of Formation (US Only)',
      },
    },
    {
      key: 'ProjectDescription',
      type: 'textarea',
      templateOptions: {
        placeholder: '--Pleaes provide a description of your project?*',
        rows: 3,
        required: true,
      },
    },
    {
      className: 'multi-select',
      key: 'TargetRoles',
      type: 'select',
      templateOptions: {
        label: 'Roles you are trying to fill. (Press ctrl to select multiple)',
        attributes:{
          style: "background: #b59673; border: 0"
        },
        multiple: true,
        options: [
          {label: '--Developer', value: 'Developer'},
          {label: '--Tester', value: 'Tester'},
          {label: '--Project Manager', value: 'Project Manager'},
          {label: '--Technical Support', value: 'Technical Support'},
          {label: '--Operations Support', value: 'Operations Support'},
        ],        
      },
    },    
    {
      key: 'TeamMembersDesired',
      type: 'select',
      templateOptions: {
        placeholder: '--How many team members are you looking to add initially?*',
        required: true,
        options: [
          { value: '1-4', label: '1-4' },
          { value: '5-10', label: '5-10' },
          { value: '11-20', label: '11-20' },
          { value: '20+', label: '20+' },
          { value: 'Not sure yet', label: 'Not sure yet'},
        ],
      },
    },
    {
      key: 'NeedSeniorTeamMembers',
      type: 'checkbox',
      templateOptions: {
        attributes:{
          style: "margin-bottom: 30px"
        },
        label: 'Do you need any senior level team members?',
      },      
    },
    {
      key: 'SeniorTeamMembers',
      type: 'input',
      templateOptions: {
        attributes:{
          style: "margin-left: 23px; width: 98%"
        },
        placeholder: '--Project Manager? Team Lead?',
      },
      hideExpression: '!model.NeedSeniorTeamMembers',
    },
    {
      key: 'ProjectDuration',
      type: 'input',
      templateOptions: {
        placeholder: '--How long are you expecting your project or need last?*',
        required: true,
      },
    },
    {
      key: 'ProjectTimeFrame',
      type: 'input',
      templateOptions: {
        placeholder: '--Are you ready to begin adding team members immediately? What\'s your time frame?*',
        required: true,
      },
    },
    {
      key: 'ProjectState',
      type: 'select',
      templateOptions: {
        placeholder: '--If this is a development project which of the following best describes it?*',
        required: true,
        options: [
          { value: 'Not a development project.', label: 'Not a development project.' },
          { value: 'Idea stage: No code written yet.', label: 'Idea stage: No code written yet.' },
          { value: 'Prototype stage: Some code but not collected into a single application.', label: 'Prototype stage: Some code but not collected into a single application.' },
          { value: 'Pre-MVP: Code written with clear direction, but not ready for users yet.', label: 'Pre-MVP: Code written with clear direction, but not ready for users yet.' },
          { value: 'MVP: Code completed and at least some users are using it.', label: 'MVP: Code completed and at least some users are using it.'},
          { value: 'Mature Application: Deployed and used on a regular basis by many users.', label: 'Mature Application: Deployed and used on a regular basis by many users.'},
          { value: 'Ageing Application: Old code or system in need of rework or to be modernized.', label: 'Ageing Application: Old code or system in need of rework or to be modernized.'},
          { value: 'Cloud Migration: Move existing application from desktop to cloud.', label: 'Cloud Migration: Move existing application from desktop to cloud.'},
          { value: 'B2B Integration: Existing software integrated with business partners.', label: 'B2B Integration: Existing software integrated with business partners.'},
        ],
      },
    },
    {
      key: 'TechTeam',
      type: 'input',
      templateOptions: {
        placeholder: '--Do you already have technical team? Please describe your team.*',
        required: true,
      },
    },
    {
      key: 'ProjectManagementSoftware',
      type: 'input',
      templateOptions: {
        placeholder: '--Do you already use project manegement software? (Jira, VSTS, GitHub, Redmine, etc...)*',
        required: true,
      },
    },
    {
      key: 'DevelopmentStack',
      type: 'input',
      templateOptions: {
        placeholder: '--Do you have a preferred technology or development stack? What is it?*',
        required: true,
      },
    },
    {
      key: 'ManagementStyle',
      type: 'select',
      templateOptions: {
        placeholder: '--How do you prefer to manage your projects? Which option best matches your needs?*',
        required: true,
        options: [
          { value: 'Hands on with daily check-ins.', label: 'Hands on with daily check-ins.' },
          { value: 'Weekly check-ins with all team members.', label: 'Weekly check-ins with all team members.' },
          { value: 'Weekly check-ins with project manager(s).', label: 'Weekly check-ins with project manager(s).' },
          { value: 'Monthly check-ins with project manager(s).', label: 'Monthly check-ins with project manager(s).' },
          { value: 'Status reports via email or other communication platform on a predetermined schedule.', label: 'Status reports via email or other communication platform on a predetermined schedule.'},
        ],
      },
    },
    {
      key: 'PreviousOffshoreExperience',
      type: 'checkbox',
      templateOptions: {
        attributes:{
          style: "margin-bottom: 30px"
        },
        label: 'Have you ever worked with an offshore team before?',
      },      
    },
    {
      key: 'OffshoreExperience',
      type: 'input',
      templateOptions: {
        attributes:{
          style: "margin-left: 23px; width: 98%"
        },
        placeholder: '--What challenges did you face? What would you change? What did you think worked well?',
      },
      hideExpression: '!model.PreviousOffshoreExperience',
    },
    {
      key: 'IdealExperience',
      type: 'input',
      templateOptions: {
        placeholder: "--In a few words describe what your looking for when working with your new team members.*",
        required: true,
      },
    },
    {
      key: 'Timezone',
      type: 'input',
      templateOptions: {
        placeholder: '--What time of day would you be working? From what timezone?*',
        required: true,
      },
    },
    {
      key: 'TimeAvailability',
      type: 'input',
      templateOptions: {
        placeholder: '--Do you have a specific time of day that you would need team members to be available?*',
        required: true,
      },
    },
    {
      key: 'PreferredLanguage',
      type: 'input',
      templateOptions: {
        placeholder: '--Primarily we work in English. If you need another language we may be able to accomodate you. Any other languages?',
      },
    },
    {
      key: 'BudgetConcerns',
      type: 'textarea',
      templateOptions: {
        placeholder: '--Typical invoicing for Team Member time is monthly. If your project or budget doesn\'t fit this schedule then please explain your situation and specific needs.',
        rows: 3,
      },
    },
  ]

  constructor(private route: ActivatedRoute, private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
    ( queryparams: Params) => {
        this.refCode = queryparams.ReferralCode;
      });
  }

  onSubmit(): void {
    this.model.ReferralCode = this.refCode;

    if(this.form.valid){
      this.auth.submitSurvey(this.model)
        .subscribe((result) => {
          this.router.navigate(['/thankyou']);
        },
        (errorResponse) => {
          console.log(errorResponse);
        });
      console.log(this.model);
    }
    // if (this.form.valid) {
    //   alert(JSON.stringify(this.model));
    // }
  }

}
