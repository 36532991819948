<!-- Start slider section-->
<div class="slider" id="top">
    <div id="preloader">
      <div id="status"></div>
    </div>
    <div class="full-slider intro">
      <!-- Start slide-->
      <div class="slide bg-mask background-image full-vh" style="background-image: url('assets/img/mtnbkg.jpg')">
        <div class="container-slide vertical-align left head-desc">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="slide-title tlt">
                  <!--Textline-->
                  <ul class="texts hidden">
                    <li data-out-effect="fadeOut" data-out-shuffle="true">Staff your next software project</li>
                    <li data-in-effect="fadeIn">Fill an urgent position</li>
                    <li data-in-effect="fadeIn">Build your MVP</li>
                    <li data-in-effect="fadeIn">Get help with technical grunt work</li>
                    <li data-in-effect="fadeIn">Migrate to the cloud</li>
          <li data-in-effect="fadeIn">Support your customers</li>
          <li data-in-effect="fadeIn">Managed Delivery Service</li>
          <li data-in-effect="fadeIn">B2B Software Integrations</li>
                  </ul>
                </div>
                <div class="heading-title-big">Project&nbsp; <span>protected</span></div>
              </div>
              <div class="col-md-8">
                <div class="description-slide">DevShield is a Technology Sourcing Agency for an offshore tech services firm trusted by companies like Google and many more.&nbsp;&nbsp;</div>
                <nav>
                  <div class="buttons-section"><a [routerLink]="'/auth/ref-code'" routerLinkActive="active" class="btn dark-btn large-btn" >Get started</a></div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Contol slider-->
    
  </div>
  
