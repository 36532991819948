<div class="dark-load">

  <header class="top-nav page-header" id="top-nav">
    <div class="container"><a class="logo smooth-scroll" href="#"><img class="logo-white" src="assets/img/grey_trans.png" alt="logo"><img class="logo-dark" src="assets/img/grey_trans.png" alt="logo"></a>
      
     
    </div>
  </header>

<router-outlet></router-outlet>


<footer>
    
  <div class="down-footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p>© 2021 DevShield, LLC - All Rights Reserved.</p>
          
        </div>
      </div>
    </div>
  </div>
</footer>

  <!-- Old browsers support--><!--[if lt IE 9]>
<script src="libs/html5shiv/es5-shim.min.js"></script>
<script src="libs/html5shiv/html5shiv.min.js"></script>
<script src="libs/html5shiv/html5shiv-printshiv.min.js"></script>
<script src="libs/respond/respond.min.js"></script>
<![endif]-->
  
  <!--button to top-->
  <!-- <div class="top icon-down toTopFromBottom"><a class="smooth-scroll" href="#top"><i class="pe-7s-angle-up"></i></a></div>-->
  <!--end button to top-->
 
  <!--Libs-->
  <script src="assets/js/libs.js"></script>
  <!--Use scripts-->
  <script src="assets/js/common.js"></script>

</div>

