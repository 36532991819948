import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private url = '/';
  constructor(private auth: AuthService, private router: Router){}

  private authState(): boolean{
    if(!this.isSurvey()){
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

  private notAuthState(): boolean {
    if(this.isSurvey()){
      return true;
    }
    this.router.navigate(['/auth/ref-code']);
    return false;
  }

  private isSurvey(): boolean{
    if(this.url.includes('/auth/survey')) {
      return true;
    }
    return false;
  }



  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any{
    this.url = state.url;
    if(this.auth.isAuthenticated()){
      return this.authState();
    }
    return this.notAuthState();
  }
    

    //route: ActivatedRouteSnapshot,
    //state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    //return true;
  
  
}
