import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Routes, RouterModule } from '@angular/router';

import { AuthComponent } from './auth.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { RefCodeComponent } from './ref-code/ref-code.component';

import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';

import { HttpClientModule } from '@angular/common/http';
import { SurveyComponent } from './survey/survey.component';

import { IConfig, NgxMaskModule } from 'ngx-mask';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};




const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'ref-code', component: RefCodeComponent},
      { path: 'survey', component: SurveyComponent, canActivate: [AuthGuard]}//}//
    ]
  }
];


@NgModule({
  declarations: [RegisterComponent, LoginComponent, RefCodeComponent, SurveyComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FormlyModule.forRoot({ extras: { lazyRender: true }}),
    FormlyBootstrapModule,
    HttpClientModule,
    NgxMaskModule.forRoot(options),
  ],
  providers: [AuthService, AuthGuard]
})
export class AuthModule { }
